(function ($) {
    var app = {
        init: function () {
            this.setup();


        },
        setup: function () {
            this.initFoundation();



        },

        //$(document).foundation();

        // };



        // var cookies = {
        //     init: function () {
        //         this.setup();
        //         this.events();
        //         // this.openModal();
        //         this.localStorageHandler('cookiesModal');
        //     },
        //     setup: function () {
        //         this.$modal = $('#cookies-modal');
        //     },
        //     getStorageData: function (name) {
        //         return localStorage.getItem(name);
        //     },
        //     setStorageData: function (name, value) {
        //         return localStorage.setItem(name, value);
        //     },
        //     openModal: function () {
        //         this.$modal.foundation('open');
        //     },
        //     closeModal: function (e) {
        //         var self = typeof e !== 'undefined' ? e.data.self : this;
        //         self.$modal.foundation('close');
        //     },
        //     checkDisplayStatus: function (name) {
        //         return this.getStorageData(name) === null;
        //     },
        //     localStorageHandler: function (name) {
        //         if (this.checkDisplayStatus(name)) {
        //             this.setStorageData(name, 'hasBeenOpen');
        //             this.openModal();
        //         }
        //     },
        //     events: function () {
        //         var self = this;
        //         this.$modal.on('open.zf.reveal', function () {
        //             $('[data-modal-close]').one('click', { self: self }, self.closeModal);
        //         });
        //     }
        // };




        // $(function () { //document ready
        //     // app.init();
        //     cookies.init();
        //     //calculatorSummary.init();
        // });




        // $(".bin").on("click", function () {
        //     $('.menu-select, .lang').removeClass('open', 300);
        // });

    }


    $('.burger-menu').click(function () {
        if ($("body").hasClass("mobile")) {
            $('body').removeClass('mobile', 300);
            $("#nav-menu").appendTo($(".menu"));
        } else {
            $('body').addClass('mobile', 300);
            $("#nav-menu").appendTo($("#mobile-menu"));
            $(".lang").appendTo($("#mobile-menu"));
        }
    });
    $('.close, #nav-menu a').click(function () {
        $('body').removeClass('mobile', 300);
        $("#nav-menu").appendTo($(".menu"));
        $(".lang").appendTo($("#right"));
    });





    $('#dropdown-button .arrow, #dropdown-button .current').click(function () {
        //$('.menu-select').addClass('open', 300);
        if ($(".menu-select").hasClass("open")) {
            $('.menu-select').removeClass('open', 300);
        } else {
            $('.menu-select').addClass('open', 300);
        }
    });

    $('.current-lang').click(function () {
        if ($(".lang").hasClass("open")) {
            $('.lang').removeClass('open', 300);
        } else {
            $('.lang').addClass('open', 300);
        }
    });



    $('.dataTables_scroll').click(function () {
        $('body').addClass('ccc');
    });

    // Oznaczanie menu w headerze
    // var $navLIs = $('.nav li')
    // $navLIs.find('a').click(function () {
    //     $navLIs.removeClass('active');
    //     $(this).parent().addClass('active');
    // }); 



    // menu active 

    // Cache selectors
    var lastId,
        topMenu = $("#nav-menu, #dot-menu"),
        topMenuHeight = topMenu.outerHeight() + 15,
        menuItems = topMenu.find("a"),
        scrollItems = menuItems.map(function () {
            var item = $($(this).attr("href"));
            if (item.length) { return item; }
        });


    menuItems.click(function (e) {
        var href = $(this).attr("href"),
            offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
        $('html, body').stop().animate({
            scrollTop: offsetTop
        }, 300);
        e.preventDefault();
    });


    $(window).scroll(function () {
        var fromTop = $(this).scrollTop() + topMenuHeight;
        var cur = scrollItems.map(function () {
            if ($(this).offset().top < fromTop)
                return this;
        });
        cur = cur[cur.length - 1];
        var id = cur && cur.length ? cur[0].id : "";

        if (lastId !== id) {
            lastId = id;
            menuItems
                .parent().removeClass("active")
                .end().filter("[href='#" + id + "']").parent().addClass("active");
        }
    });
    $('#tid, #tel').bind('keyup paste input', function () {
        this.value = this.value.replace(/[^0-9-]/g, '');
    });
    /* --- placeholder ----  */
    $('input').focus(function () {
        placeholder = $(this).attr('placeholder');
        if (placeholder != undefined) {
            $(this).parent().prepend('<span class="inp-placeholder">' + placeholder + '</span>');
        }
    });
    $('input').blur(function () {
        $(this).parent().find('.inp-placeholder').remove();
    });

    

})(jQuery);